<template>
  <div class="page page--bottom-indent edit-survey">
    <esmp-tabs
      :animated="false"
      v-model="activeTab"
      :active-sub-tab-id="activeSubTabId"
      @on-click-sub-tab="selectSubTabId"
    >
      <template v-if="activeTab === 'construct'" #extra>
        <div class="edit-survey__right-bar">
          <div @click="toggleView" class="edit-survey__expander">
            <esmp-icon :name="expandAll ? 'arrow-up' : 'arrow-down'" />
            <span class="edit-survey__expander-text">
              {{ expandAll ? 'Скрыть' : 'Раскрыть' }} все контролы
            </span>
          </div>
        </div>
      </template>
      <esmp-tab-pane
        label="Конструктор"
        name="construct"
      >
        <service-form
          v-model="elementList"
          :controls="controlList"
          :is-control-sorting="false"
        />
      </esmp-tab-pane>
      <esmp-tab-pane
        label="Настройки"
        name="settings"
      >
        <div class="edit-survey__settings">
          <div class="edit-survey__setting">
            <h3>Название анкеты *</h3>
            <esmp-input
              v-model="settings.name"
              class="edit-survey__field"
            />
          </div>
          <div class="edit-survey__setting">
            <h3>Описание</h3>
            <esmp-input
              v-model="settings.description"
              :options="{type: 'textarea'}"
              class="edit-survey__field"
            />
          </div>
          <div class="edit-survey__setting">
            <h3>Дата старта опроса *</h3>
            <esmp-datepicker-adaptive
              v-model="startDatetime"
              placeholder="Старт опроса"
              class="edit-survey__field"
            />
          </div>
          <div class="edit-survey__setting">
            <h3>Дата завершения опроса *</h3>
            <esmp-datepicker-adaptive
              v-model="finishDatetime"
              placeholder="Завершение опроса"
              class="edit-survey__field"
            />
          </div>
          <div class="edit-survey__setting">
            <h3>Техническое имя</h3>
            <esmp-input
              v-model="settings.techName"
              class="edit-survey__field"
            />
          </div>
          <div class="edit-survey__setting">
            <h3>Комментарий</h3>
            <esmp-input
              v-model="settings.comment"
              :options="{type: 'textarea'}"
              class="edit-survey__field"
            />
          </div>
          <div class="edit-survey__setting" v-if="surveyPriorities?.length">
            <h3>Приоритет</h3>
            <esmp-select2
              class="edit-survey__field"
              v-model="settings.priority"
              :options="surveyPriorities"
              label-prop="translatedName"
            />
          </div>
          <div class="edit-survey__setting">
            <subscription-list
              v-model="settings.subscriptions"
              :can-import-from-esmp-sm="false"
              @on-delete="deleteSubscription"
            />
          </div>
        </div>
        <!--
        <template v-if="activeSubTabId === 'settingsDisplay'">
          <div class="edit-survey__setting edit-survey__logo-setting">
            <esmp-upload
              class="edit-survey__upload-logo"
              action="/api/files"
              type="drag"
              auto-upload
              :on-success="onUploadImage"
              :show-upload-list="false"
            >
              Добавить логотип
            </esmp-upload>
            <div v-if="logo" class="edit-survey__logo">
              <img :src="`http://dev-tbkrv.ks.rt.ru${logo.url}`">
              <esmp-button
                icon="trash"
                view="function"
                @click="removeLogo"
              />
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>Название анкеты</h3>
            <esmp-input
              v-model="survey.name"
              class="edit-survey__field"
            />
            <esmp-checkbox size="small" v-model="survey.isShowedName">
              Отображать название анкеты при её заполнении
            </esmp-checkbox>
          </div>
          <div class="edit-survey__setting">
            <h3>Страница приветствия</h3>
            <wysiwyg v-model="survey.welcome" class="edit-survey__field" />
            <esmp-checkbox size="small" v-model="survey.isShowedWelcome">
              Отображать страницу приветствия
            </esmp-checkbox>
          </div>
          <div class="edit-survey__setting">
            <h3>Страницы завершения</h3>
            <div class="edit-survey__page-actions">
              <esmp-button
                v-for="(page, index) in survey.finishPages"
                :key="`page-${index}`"
                view="outline"
                size="small"
                :class="{'edit-survey__active-page': currentPageIndexes.finishPages === index}"
                @click="selectPage('finishPages', index)"
              >
                {{ index + 1 }}
              </esmp-button>
              <esmp-button
                view="outline"
                size="small"
                icon="plus"
                @click="addPage('finishPages')"
              />
            </div>
            <div
              class="edit-survey__page"
            >
              <div class="edit-survey__header">
                <h4>{{ currentPageIndexes.finishPages + 1 }}</h4>
                <esmp-button
                  v-if="survey.finishPages.length > 1"
                  view="function"
                  size="small"
                  icon="24-trash"
                  @click="removePage('finishPages', currentPageIndexes.finishPages)"
                />
              </div>
              <esmp-input
                class="edit-survey__field"
                label="Название страницы"
              />
              <wysiwyg
                class="edit-survey__field"
                v-model="survey.finishPages[currentPageIndexes.finishPages].content"
              />
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>Страницы дисквалификации</h3>
            <div class="edit-survey__page-actions">
              <esmp-button
                v-for="(page, index) in survey.disPages"
                :key="`page-${index}`"
                view="outline"
                size="small"
                :class="{'edit-survey__active-page': currentPageIndexes.disPages === index}"
                @click="selectPage('disPages', index)"
              >
                {{ index + 1 }}
              </esmp-button>
              <esmp-button
                view="outline"
                size="small"
                icon="plus"
                @click="addPage('disPages')"
              />
            </div>
            <div
              class="edit-survey__page"
            >
              <div class="edit-survey__header">
                <h4>{{ currentPageIndexes.disPages + 1 }}</h4>
                <esmp-button
                  v-if="survey.disPages.length > 1"
                  view="function"
                  size="small"
                  icon="24-trash"
                  @click="removePage('disPages', currentPageIndexes.disPages)"
                />
              </div>
              <esmp-input
                class="edit-survey__field"
                label="Название страницы"
              />
              <wysiwyg class="edit-survey__field" v-model="survey.disPages[currentPageIndexes.disPages].content" />
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>Текст кнопок</h3>
            <div class="edit-survey__grid">
              <esmp-input
                v-model="survey.buttons.start"
                label="Начать"
                class="edit-survey__field"
              />
              <esmp-input
                v-model="survey.buttons.finish"
                label="Завершить"
                class="edit-survey__field"
              />
              <esmp-input
                v-model="survey.buttons.next"
                label="Вперёд"
                class="edit-survey__field"
              />
              <esmp-input
                v-model="survey.buttons.prev"
                label="Назад"
                class="edit-survey__field"
              />
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>Показ количества вопросов в анкете</h3>
            <esmp-checkbox v-model="survey.isShowedQuestionCount">
              Показывать
            </esmp-checkbox>
            <div class="edit-survey__hint">
              Функция позволяет отключить для респондента показ количества вопросов в Вашей анкете.
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>Показ шкалы прохождения в анкете</h3>
            <esmp-checkbox v-model="survey.isShowedProgress">
              Показывать
            </esmp-checkbox>
            <div class="edit-survey__hint">
              Функция позволяет включить для респондента показ шкалы прохождения в Вашей анкете.
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>Кнопки соц. сетей на странице завершения</h3>
            <esmp-checkbox v-model="survey.isShowedSocial">
              Показывать
            </esmp-checkbox>
          </div>
          <div class="edit-survey__setting">
            <h3>Отображение кнопки "Скачать свой ответ"</h3>
            <esmp-checkbox v-model="survey.isShowedDownloadAnswer">
              Показывать
            </esmp-checkbox>
          </div>
          <div class="edit-survey__setting">
            <h3>Настройка редиректов</h3>
            <div
              class="edit-survey__field"
              v-for="(redirect, index) in survey.redirects"
              :key="index"
            >
              <esmp-checkbox v-model="redirect.isSelected">
                {{ redirect.title }}
              </esmp-checkbox>
              <div class="edit-survey__grid">
                <esmp-input
                  v-model="redirect.url"
                  label="url"
                />
                <esmp-input
                  v-model="redirect.time"
                  label="Таймаут (секунд)"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="activeSubTabId === 'settingsFill'">
          <div class="edit-survey__setting">
            <h3>Повторное заполнение анкеты</h3>
            <esmp-radio-group
              v-model="survey.allowMultipleAnswer"
              :items="allowMultipleAnswers"
            />
            <div class="edit-survey__hint">
              Факт заполнения анкеты фиксируется с помощью IP-адреса.
            </div>
          </div>
        </template>
        <template v-else-if="activeSubTabId === 'settingsSharingLink'">
          <div class="edit-survey__setting">
            <h3>Прямая ссылка</h3>
            <div class="edit-survey__field">
              <esmp-input
                v-model="result.url"
                disabled
              />
              <div class="edit-survey__url-actions">
                <esmp-button
                  icon="copy"
                  view="function"
                  @click="copyUrl(result.url)"
                />
                <esmp-button
                  icon="24-link-native"
                  view="function"
                  @click="openUrl(result.url)"
                />
              </div>
            </div>
            <div class="edit-survey__hint">
              Респонденты будут переходить прямо по этой ссылке.
            </div>
          </div>
          <div class="edit-survey__setting">
            <h3>HTML-код</h3>
            <esmp-input
              v-model="result.html"
              disabled
              :options="{type: 'textarea'}"
              class="edit-survey__field"
            />
            <div class="edit-survey__hint">
              Этот код Вы можете разместить на своем сайте, в блоге.
            </div>
          </div>
          <div class="edit-survey__setting" v-if="result.qrcode">
            <h3>QR-код</h3>
            <img :src="result.qrcode" class="edit-survey__field">
            <div class="edit-survey__hint">
              QR-код – миниатюрный носитель данных, также содержащий ссылку на Вашу анкету.
            </div>
          </div>
        </template>
        <template v-else>
          <div class="edit-survey__setting">
            <h3>Режим "Тест</h3>
            <esmp-checkbox v-model="survey.isTest">
              Включить
            </esmp-checkbox>
          </div>
        </template>
        -->
      </esmp-tab-pane>
      <esmp-tab-pane
        v-if="!isCreateMode"
        label="Результаты"
        name="results"
        :children="subResults"
      >
        <template v-if="activeSubTabId === 'answers'">
          <h3>Ответы</h3>
          <div class="edit-survey__result-types">
            <esmp-button
              v-for="type in RESULT_TYPES"
              :key="type.id"
              view="outline"
              size="small"
              shape="circular"
              :class="{'edit-survey__active-page': activeResultType === type.id}"
              @click="selectResultType(type.id)"
            >
              {{ type.name }}
            </esmp-button>
          </div>
          <esmp-table
            :columns="resultColumns"
            :rows="resultRows"
            stripe
          />
        </template>
        <template v-else>
          <div class="edit-survey__header edit-survey__header--end">
            <esmp-button size="small" @click="createSurveyReport">
              Создать отчёт
            </esmp-button>
          </div>
          <esmp-table
            :key="currentPage"
            :columns="surveyReportsColumns"
            :rows="surveyReports"
            stripe
          >
            <template #cell-state="{ td }">
              <esmp-tag
                :color="getStatus(td).color"
                :is-status="true"
                class="typo-caption"
              >
                {{ getStatus(td).name }}
              </esmp-tag>
            </template>
            <template #cell-startDatetime="{ td }">
              {{ formatDate(td) }}
            </template>
            <template #cell-finishDatetime="{ td }">
              {{ formatDate(td) }}
            </template>
            <template #cell-fileMetaData="{ td }">
              <esmp-button
                v-if="td"
                icon="24-cloud-download"
                view="function"
                @click="downloadFile(td)"
              />
            </template>
          </esmp-table>
          <esmp-pagination-adaptive
            v-if="pageCount > 1"
            class="edit-survey__pagination"
            :page-count="pageCount"
            :current-page.sync="currentPage"
          />
        </template>
      </esmp-tab-pane>
    </esmp-tabs>
    <div
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        class="page__action-button"
        @click="save"
      >
        Сохранить
      </esmp-button>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
// import Wysiwyg from '@/components/wysiwyg';
import ServiceForm from '@/components/service-form';
import Hub from '@/plugins/event-hub';
import convertDate from '@/helpers/convertDate';
import SubscriptionList from '@/pages/admin/forms/components/SubscriptionList.vue';

import {
  mappedControls,
  setOrder,
  setTechId,
} from '@/helpers/controls';
import REPORTS_PAGINATION_SIZE from '@/constants/survey';
import { getStatus } from '@/helpers/status';
import { saveAs } from 'file-saver';

const RESULT_TYPES = [
  {
    id: 'active',
    name: 'Активные',
  },
  {
    id: 'remove',
    name: 'Удалённые',
  },
  {
    id: 'disqualification',
    name: 'Дисквалифицированные',
  },
  {
    id: 'notComplete',
    name: 'Незавершённые',
  },
];

const DEFAULT_PRIORITY_NAME = 'middle';

export default {
  name: 'EditSurvey',
  components: {
    // Wysiwyg,
    ServiceForm,
    SubscriptionList,
  },
  data() {
    return {
      activeTab: 'construct',
      activeSubTabId: 'settingsDisplay',
      subSettings: [
        {
          id: 'settingsDisplay',
          name: 'Настройки отображения',
        },
        {
          id: 'settingsFill',
          name: 'Настройки заполнения',
        },
        {
          id: 'settingsSharingLink',
          name: 'Сбор ответов',
        },
        {
          id: 'settingsTests',
          name: 'Режим "Тест"',
        },
      ],
      logo: '',
      survey: {
        name: '',
        isShowedName: false,
        welcome: 'bhjbju',
        isShowedWelcome: false,
        finishPages: [
          {
            id: 1,
            content: 'Благодарим Вас за честные ответы и потраченное время!',
          },
          {
            id: 2,
            content: 'Мы обязательно учтем Ваше мнение!',
          },
        ],
        disPages: [
          {
            id: 1,
            content: 'Данный опрос направлен на фокусное исследование аудитории по заданным параметрам.',
          },
          {
            id: 2,
            content: 'Благодарим Вас за уделённое время.',
          },
        ],
        buttons: {
          start: '',
          finish: '',
          next: '',
          prev: '',
        },
        isShowedQuestionCount: false,
        isShowedProgress: false,
        isShowedSocial: false,
        isShowedDownloadAnswer: false,
        redirects: [
          {
            type: 'finish',
            isSelected: false,
            title: 'Редирект при завершении анкеты',
            url: '',
            time: 0,
          },
          {
            type: 'disqualification',
            isSelected: false,
            title: 'Редирект при дисквалификации',
            url: '',
            time: 0,
          },
          {
            type: 'disqualificationQuote',
            isSelected: false,
            title: 'Редирект при дисквалификации по квоте',
            url: '',
            time: 0,
          },
        ],
        allowMultipleAnswer: null,
        isTest: false,
      },
      currentPageIndexes: {
        finishPages: 0,
        disPages: 0,
      },
      allowMultipleAnswers: [
        {
          label: 'Разрешить',
          value: 1,
        },
        {
          label: 'Запретить по ip',
          value: 2,
        },
        {
          label: 'Запретить по cookie',
          value: 3,
        },
        {
          label: 'Запретить по ip + cookie',
          value: 4,
        },
      ],
      result: {
        url: 'https://anketolog.ru/s/701695/Hvdob79u',
        html: '<a target=\'_blank\' href=\'https://anketolog.ru/s/701695/Hvdob79u\'>Заполните нашу анкету</a>',
      },
      activeResultType: 'active',
      resultColumns: [
        {
          title: 'Имя сотрудника',
          key: 'name',
        },
        {
          title: 'Email',
          key: 'email',
        },
      ],
      resultRows: [
        {
          name: 'Иван Иванов',
          email: 'ivan@ivanov.ru',
        },
        {
          name: 'Иван Иванов',
          email: 'ivan@ivanov.ru',
        },
        {
          name: 'Иван Иванов',
          email: 'ivan@ivanov.ru',
        },
        {
          name: 'Иван Иванов',
          email: 'ivan@ivanov.ru',
        },
      ],

      elementList: [],
      settings: {
        name: 'Название',
        description: 'Описание',
        startDatetime: null,
        finishDatetime: null,
        techName: 'Техническое имя',
        comment: 'Комментарий',
        subscriptions: [],
        priority: null,
      },
      controlList: [],
      expandAll: false,
      RESULT_TYPES,
      surveyReports: [],
      pageCount: 1,
      currentPage: 1,
      surveyReportsColumns: [
        {
          title: 'Статус выгрузки',
          key: 'state',
        },
        {
          title: 'Кто заказал',
          key: 'userLogin',
        },
        {
          title: 'Дата создания',
          key: 'startDatetime',
        },
        {
          title: 'Дата получения',
          key: 'finishDatetime',
        },
        {
          title: 'Выгрузить',
          key: 'fileMetaData',
        },
      ],
      subResults: [
        // {
        //   id: 'answers',
        //   name: 'Ответы ',
        // },
        {
          id: 'reports',
          name: 'Отчёты',
        },
      ],
      surveyPriorities: null,
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    isCreateMode() {
      return this.$route.name === 'CreateSurvey';
    },
    surveyType() {
      return this.$route?.query?.type || 'survey';
    },
    finishText() {
      return this.survey.finishPages.find((i) => i.content)?.content || '';
    },
    startDatetime: {
      get() {
        return this.settings?.startDatetime
          ? dayjs(this.settings.startDatetime).toDate()
          : null;
      },
      set(date) {
        this.settings.startDatetime = date ? convertDate(date) : null;
      },
    },
    finishDatetime: {
      get() {
        return this.settings?.finishDatetime
          ? dayjs(this.settings.finishDatetime).toDate()
          : null;
      },
      set(date) {
        this.settings.finishDatetime = date ? convertDate(date) : null;
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getStatus,
    save() {
      if (!this.validateSettings()) {
        this.$EsmpNotify.$show('Настройки не заполнены', 'error');
        return;
      }
      const dto = omit(this.settings, 'formVersionId');
      dto.formItems = setOrder(cloneDeep(this.elementList));

      const saveMethod = this.isCreateMode ? 'createSurvey' : 'updateSurvey';

      this.setLoading({ key: 'page', value: true });
      this.$API[this.surveyType][saveMethod](dto)
        .then(() => {
          this.$EsmpNotify.$show('Форма успешно сохранена', 'success');
          this.$router.push({ name: 'Surveys' });
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    getControls() {
      const method = this.surveyType === 'survey'
        ? 'getSurveyControls'
        : 'getIndexSurveyControls';
      return this.$API.controls[method]().then(({ data }) => {
        this.controlList = mappedControls(data) || [];
      });
    },
    toggleView() {
      this.expandAll = !this.expandAll;
      Hub.$emit('toggle-views', this.expandAll);
    },
    async getSurveySubscription() {
      await this.$API.survey.getSurveySubscription(this.$route.params.id)
        .then(({ data }) => {
          this.settings.subscriptions = data;
        });
    },
    async init() {
      await this.$API[this.surveyType].getSurvey(this.$route.params.id)
        .then(({ data }) => {
          this.elementList = setTechId(data.formItems);
          this.settings = omit(data, 'formItems');
        });
    },
    validateSettings() {
      return this.settings.name
        && this.settings.description
        && this.settings.startDatetime
        && this.settings.finishDatetime
        && this.settings?.subscriptions?.length
        && this.settings.priority;
    },

    // скрытые настройки
    onUploadImage(res) {
      this.logo = res;
    },
    removeLogo() {
      this.logo = null;
    },
    selectPage(type, pageIndex) {
      this.currentPageIndexes[type] = pageIndex;
    },
    addPage(type) {
      this.survey[type].push({
        content: 'Мы обязательно учтем Ваше мнение!',
      });
      this.currentPageIndexes[type] = this.survey[type].length - 1;
    },
    removePage(type, index) {
      this.survey[type].splice(index, 1);
      this.currentPageIndexes[type] = this.survey[type].length - 1;
    },
    selectSubTabId(subTabId) {
      this.activeSubTabId = subTabId;
    },
    getQRCode() {
      return QRCode.toDataURL('I am a pony!')
        .then((url) => {
          this.result.qrcode = url;
        });
    },
    copyUrl(url) {
      navigator.clipboard.writeText(url);
      this.$EsmpNotify.$show('Ссылка скопирована в буфер обмена', 'success');
    },
    openUrl(url) {
      window.open(url, '_blank');
    },
    // end: скрытые настройки

    selectResultType(type) {
      this.activeResultType = type;
    },
    createSurveyReport() {
      this.setLoading({ key: 'page', value: true });
      this.$API[this.surveyType].createSurveyReport(this.$route.params.id)
        .then(({ data }) => {
          this.surveyReports.unshift(data);
        }).finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    getSurveyReports() {
      if (!this.surveyReports.length) {
        this.setLoading({ key: 'page', value: true });
      }
      this.$API[this.surveyType].getSurveyReports(this.$route.params.id, REPORTS_PAGINATION_SIZE, this.currentPage - 1)
        .then(({ data }) => {
          this.surveyReports = data.content;
          this.pageCount = data.totalPages;
        }).finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    formatDate(date) {
      if (date) {
        return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
      }
      return null;
    },
    downloadFile(file) {
      if (file.url) {
        saveAs(file.url, file.originalFilename || file.name);
      }
    },
    getSurveyPriorities() {
      this.$API.survey.getSurveyPriorities()
        .then(({ data }) => {
          this.surveyPriorities = data;
          if (this.isCreateMode) {
            this.settings.priority = this.surveyPriorities.find((i) => i.name === DEFAULT_PRIORITY_NAME) || null;
          }
        });
    },
    deleteSubscription(index) {
      this.settings.subscriptions = this.settings.subscriptions.filter((s, i) => i !== index);
    },
  },
  watch: {
    activeTab(val) {
      if (this.reportsInterval) clearInterval(this.reportsInterval);
      if (val === 'settings') {
        this.activeSubTabId = 'settingsDisplay';
      } else if (val === 'results') {
        this.activeSubTabId = 'reports';
      }
    },
    activeSubTabId(val) {
      if (this.reportsInterval) clearInterval(this.reportsInterval);
      if (this.activeTab === 'results') {
        this.activeResultType = 'active';
      }
      if (val === 'reports') {
        if (!this.isCreateMode) {
          this.getSurveyReports(this.$route.params.id, REPORTS_PAGINATION_SIZE, this.currentPage - 1);
          this.reportsInterval = setInterval(() => {
            this.getSurveyReports(this.$route.params.id, REPORTS_PAGINATION_SIZE, this.currentPage - 1);
          }, 10000);
        }
      }
    },
    currentPage(val) {
      this.getSurveyReports(this.$route.params.id, REPORTS_PAGINATION_SIZE, val - 1);
    },
  },
  mounted() {
    this.getSurveyPriorities();
    this.getControls();
    if (!this.isCreateMode) {
      this.init();
      this.getSurveySubscription();
    }
    // this.getQRCode();
  },
  destroyed() {
    if (this.reportsInterval) clearInterval(this.reportsInterval);
  },
};
</script>

<style lang="scss">
.edit-survey {
  &__setting {
    margin-bottom: $base-gutter;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $base-gutter;

    h4 {
      background-color: rgba($color-primary-1-day, .1);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-right: $base-gutter;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--wide {
      justify-content: space-between;
    }
    &--end {
      justify-content: flex-end;
    }
  }
  &__page-actions {
    display: flex;
    align-items: center;
    gap: $base-gutter;
    margin-bottom: $base-gutter;

    .esmp-button--small {
      min-width: 35px;
    }
  }
  &__active-page {
    border-color: var(--esmp-ui-button-view-outline-hover-border-color);
    background-color: var(--esmp-ui-button-view-outline-hover-background-color);
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
  }
  h3 {
    margin-bottom: $base-gutter;
  }
  &__field {
    margin-bottom: $base-gutter / 2;
    position: relative;
  }
  &__logo-setting {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
  &__logo {
    height: 69px;
    display: flex;
    align-items: flex-start;

    img {
      height: 100%;
    }
  }
  &__hint {
    font-size: 12px;
  }
  &__url-actions {
    position: absolute;
    right: $base-gutter;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__result-types {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__right-bar {
    display: flex;
    align-items: center;
  }
  &__expander {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 200px;
    &:hover {
      cursor: pointer;
    }

    &-text {
      display: inline-block;
      margin-bottom: -4px;
    }
  }
  &__pagination {
    justify-content: center;
    padding: $base-gutter 0;
  }
}
</style>
